import React from "react";
import { Link } from "react-router-dom";
import "./styles.css"; // Import your CSS stylesheet

export default function NotFound() {
  return (
    <div className="not-found-container">
      <h1 className="not-found-title">Oops! Her var det ikke så mye...</h1>
      <p className="not-found-text">
        Vi kunne ikke finne den siden du leter etter.
      </p>
      <Link to="/" className="not-found-link">
        Ta meg hjem
      </Link>
    </div>
  );
}
