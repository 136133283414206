import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import './styles.css';
import rocket from './images/rocket.svg';
import _logo from './images/logo.png';
import instalogo from './images/Instagram-PNGinstagram-icon.png';

const stickyHeader = () => {
  var header = document.getElementById("NavbarStandard");
    if(window.scrollY>40){
      header.classList.add("sticky-transparent");
    }
    else{
      header.classList.remove("sticky-transparent");
  }
}

const _links = [
  {
    label: "Hjem",
    url: "/",
  },
  {
    label: "Om oss",
    url: "/about",
  },
  {
    label: "Produkter",
    url: "/products",
  }
];

const Module = ({ links=_links, logo=_logo, buttonText="Klikk meg" }) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => { setOpen(!open) };
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [location]);

  useEffect(() => {
    window.onscroll = function () { stickyHeader() };
    return () => { window.onscroll = null }
  }, []);

  return (
    <nav id="NavbarStandard" className={`NavbarStandard navbar navbar-expand-lg${open ? 'mobile-menu-open' : ''}`}>
      <div className="container">
        <Link className="navbar-brand" to="/"><img src={logo} alt="Logo" /></Link>
        <button
          className={`navbar-toggler ${open ? '' : 'collapsed'}`}
          type="button"
          onClick={toggleOpen}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${open ? 'show' : ''}`}>
          <ul className="navbar-nav ms-auto align-items-center">
            {links.map((link, index) => {
              return (
                <li key={index} className="nav-item">
                  <Link onClick={() => setOpen(false)} className="nav-link" to={link.url}>{link.label}</Link>
                </li>
              )
            })}
            <li className="nav-item">
              <Link onClick={() => setOpen(false)} className="nav-link icon" to="https://www.instagram.com/wimaeiendom/">
                <i class="fa fa-instagram"></i>
              </Link>
            </li>
            <li className="nav-item">
              <Link onClick={() => setOpen(false)} className="nav-link btn-nav" to="/kontakt">
                <img src={rocket} alt="Rocket" />{buttonText}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Module;
