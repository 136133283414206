import { Link } from "react-router-dom";
import './styles.css';


const Module = ({title="Title", subtitle="subtitle med mye text"}) => {
  return (
    <div className="container-fluid B5_S3">
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section-title">{title}</h2>
              <p className="section-subtitle">{subtitle}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Module;
