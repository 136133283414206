import { Link } from "react-router-dom";
import './styles.css';

import image1 from './images/image1.svg';
import image2 from './images/image2.svg';
import image3 from './images/image3.svg';
import image4 from './images/image4.svg';
import image5 from './images/image5.svg';


const _items = [
  {title: 'NYBYGG OG TILBYGG', image: image1, link: '#'},
  {title: 'TOTALRENOVERING OG OPPUSSING', image: image2, link: '#'},
  {title: 'BAD OG KJØKKEN', image: image3, link: '#'},
  {title: 'TAK, TERRASSE OG UTEOMRÅDE', image: image4, link: '#'},
  {title: 'MURING OG BETONGARBEID', image: image5, link: '#'},
];

const Module = ({title="Tittel", subtitle="Subtittel med masse ord", items=_items, buttonText="Les mer"}) => {
  return (
    <div className="container-fluid BoxCircle">
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section-title">{title}</h2>
              <p className="section-subtitle">{subtitle}</p>
            </div>
          </div>
          <div className="row">
            {items.map((item, index) => (
              <div className="col-md" key={index}>
                <div className="services-item-cover">
                <Link to={item.link} className="services-item-link">
                  <div className="services-item">
                    <img src={item.image} />
                  </div>
                  <p>{item.title}</p>
                </Link>
              </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-md-12">
              <Link to="/tjenester" className="section-btn">{buttonText}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};




export default Module;
