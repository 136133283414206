import { Link } from "react-router-dom";
import './styles.css';
import _logo from './logo.png';


const _paragraphs = [
  "Keel AS",
  "Org.nr: 123 456 789",
  "1234 Oslo",
  "Norway"
];  

const Module = ({logo=_logo, paragraphs=_paragraphs, email="info@keel.no"}) => {

  return (
    <div className='FooterSmall'>
      <div className="container-fluid footer">
        <Link to="#" className="footer-logo">
          <img src={logo} />
        </Link>
        {paragraphs.map((paragraph, index) => (
          <div key={index}>{paragraph}</div>
        ))}

        <p><Link to={"mailto:" + email} className="footer-mail">
          {email}
        </Link><br /></p>

        <div className="row copyright">
          <Link to="http://keel.no" target="_blank" className="pt-2 pt-lg-0">
            Designed and developed by Keel.no | © Keel 2023
          </Link>
        </div>
      </div>
    </div>
  )
};

export default Module;
