import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import './styles.css';

import image1 from './images/slider/image1-min.png';
import image2 from './images/slider/image2-min-min.jpg';
import image3 from './images/slider/image3.jpg';
import image4 from './images/slider/image4-min.jpg';

const _images = [image1, image2, image3, image4];

const Module = ({ images=_images, title="Tittel", subtitle="Subtittel med masse ord" }) => {
  const [index, setIndex] = useState(0);


  const preloadedImages = images.map(image => {
    const img = new Image();
    img.src = image;
    return img;
  });

  useEffect(() => {
    const changeImage = () => {
      setIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % preloadedImages.length;

        const previousImage = document.getElementById("image" + prevIndex);
        previousImage.style.visibility = "hidden";
        const Nextimage = document.getElementById("image" + nextIndex);
        Nextimage.style.visibility = "visible";


        return nextIndex
      })
    }

    const intervalId = images.length > 0 ? setInterval(changeImage, 4000) : null; // Change image every 5 seconds
    return () => {
      clearInterval(intervalId); // Cleanup on unmount
    };

  }, []);

  const slides = preloadedImages.map((image, _index) => {
    const backgroundStyle = {
      backgroundImage: `linear-gradient(rgb(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${image.src})`,
      backgroundPosition: 'center center',
      visibility: _index == 0 ? "visible" : "hidden",
    };

    return (
      <div key={_index} id={"image" + _index} className="background" style={backgroundStyle} ></div>
    );
  });

  return (
    <div className="container-fluid banner HeroSlider" id="hero">
      {slides.map((slide) => slide)}
      <div className="custom-container">
        <div className="row">
          <div className="col-md-12 wrapper ">
            <div className="banner-content HeroSlider-content">
              <div className="slider">
                <h1>{title}</h1>
                <p>{subtitle}</p>
                <a href="#anchor" className="arrow-scroll-down">
                  <i className="fa fa-chevron-down" id="anchor"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Module;