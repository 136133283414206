import { Link } from "react-router-dom";
import './styles.css';
import React, { useState } from 'react';


const Module = () => {
  const [buttonText, setButtonText] = useState('Send');
  const [formData, setFormData] = useState({
    'First-name': '',
    'Phone': '',
    'email': '',
    'Project-Summary': ''
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText('Sender...');

    const data = {
      recipient_email: process.env.REACT_APP_CONTACT_EMAIL_RECIPIENT,
      subject: "Ny forespørsel fra nettstedet",
      message: `Navn: ${formData['First-name']}, Epost: ${formData.email}, Telefon: ${formData.Phone}, Beskrivelse: ${formData['Project-Summary']}`
    };

    try {
      const response = await fetch('/mailserver', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      const responseData = await response.json();
      if (response.ok) {
        alert('E-post sendt!');
        setButtonText('Send');
        setFormData({
          'First-name': '',
          'Phone': '',
          'email': '',
          'Project-Summary': ''
        });
      } else {
        throw new Error(responseData.error);
      }
    } catch (error) {
      setButtonText('Send');
      alert('E-post kunne ikke sendes. Vennligst prøv igjen senere.')
    }
  }

  return (
    <div className='FormContactLarge'>
      <div className="container-fluid form-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Bestill gratis befaring</h1>
            </div>
          </div>
          <div className='kontakt-info'>
            <span className='kontakt-person'>
              <h2>Marius Sagbråten</h2>
              <div className='kontakt-title'>Prosjektleder</div>
              <Link style={{ textDecoration: 'none', color: 'inherit' }} to="tel:+980 02 595">980 02 595</Link>
              <div>marius@wimabygg.no</div>
            </span>
            <span className='kontakt-person'>
              <h2>Wilhelm Koren</h2>
              <div className='kontakt-title'>Prosjektleder</div>
              <Link style={{ textDecoration: 'none', color: 'inherit' }} to="tel:+922 88 760">922 88 760</Link>
              <div>wilhelm@wimabygg.no</div>
            </span>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-cover">
                <form className="form" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="First-name" className="field-label">Navn</label>
                      <input onChange={handleInputChange} type="text" className="form-control" maxLength="256" name="First-name" data-name="First name" placeholder="Ditt navn" id="First-name" required="" />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="Phone" className="field-label">Telefon</label>
                      <input  onChange={handleInputChange} type="tel" className="form-control" maxLength="256" name="Phone" data-name="Phone" placeholder="Ditt nummer" id="Phone" />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email" className="field-label">Mail</label>
                      <input  onChange={handleInputChange}  type="email" className="form-control" maxLength="256" name="email" data-name="Email" placeholder="navn@mail.com" id="email" required="" />
                    </div>
                    <div className="col-md-12">
                      <label className="last-label">Hva kan vi hjelpe deg med?</label>
                    </div>
                    <div className="col-md-12">
                      <textarea  onChange={handleInputChange}  id="Project-Summary" name="Project-Summary" maxLength="5000" data-name="field" placeholder="Prosjektbeskrivelse" required="" className="form-control"></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <input type="submit" value={buttonText} className="btn-submit" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Module;
