// Fonts.js
import { createGlobalStyle } from 'styled-components';

const Fonts = createGlobalStyle`
  /* Import and define your fonts here */
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Agbalumo&family=Quicksand&family=Raleway&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
  `;

export default Fonts;
