import { Link } from "react-router-dom";
import './styles.css';

const _subtitle = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, molestie ipsum et, consequat nibh. Etiam non elit dui. Nullam vel eros sit amet arcu vestibulum accumsan in in leo.";  

const Module = ({title="Tittel", subtitle=_subtitle, buttonText="Les mer"}) => {
  return (
    <div className="container-fluid CtaText">
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section-title">{title}</h2>
              <p className="section-subtitle">{subtitle}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Link to="/kontakt" className="section-btn">{buttonText}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Module;
