import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Fonts from './Fonts';

import template from './templates';
import Error from './templates/error/Error';

import './App.css';

// Sets the basename conditionally based on the subdomain
// Only use for Link components
function getBasename() {
  const segments = window.location.pathname.split('/').filter(segment => 
    segment.length > 0);
  return "/".concat(segments.length > 0 ? segments[0] : "");
}

const basename = window.location.hostname.includes("dev.keel.no") ? 
  getBasename() : "/";

  const router = createBrowserRouter([
  ...template,
  {
    path: "*",
    element: <Error />
  },
], {basename: basename});


function App() {
  return (
    <>
      <Fonts />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
