import { Link } from "react-router-dom";
import './styles.css';

import image1 from './images/image1.jpg';
import image2 from './images/image2.jpg';
import image3 from './images/image3.jpg';

const _items = [{
  image: image1,
  title: "Tjeneste 1",
  link: "/",
},
{
  image: image2,
  title: "Tjeneste 2",
  link: "/",
},
{
  image: image3,
  title: "Tjeneste 3",
  link: "/",
},
];


const Module = ({ items = _items }) => {
  return (
    <div className="container-fluid GalleryButtons">
      <div className="container-fluid ">
        <div className="container">
          <div className="row">
            {items.map(({ image, title, link }, key) => (
              <div className="col-md-4 mod-item-cover">
                <div className="mod-item">
                  <h3>{title}</h3>

                  {link ?
                    <Link to={link}>
                      <div className="mod-item-image scale" >
                        <img src={image} />
                      </div>
                    </Link>
                    :
                    <div className="mod-item-image" >
                      <img src={image} />
                    </div>
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Module;
