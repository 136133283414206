import * as img from "../images"

import {
    NavbarStandard,
    FooterSmall,
    HeroSlider,
    BoxCircle,
    CtaText,
    GalleryButtons,
    GalleryButtons_Texted,
    PictureText,
    CardImageRight,
    CardImageLeft,
    FormContactLarge,

} from "../modules";

const Header = () => <>
    <NavbarStandard
        fixed={true}
        transparent={true}
        logo={img.logo}
        buttonText="KONTAKT OSS"
        links={[{
            label: "TJENESTER",
            url: "/tjenester",
        },
        {
            label: "OM OSS",
            url: "/om-oss",
        },
        {
            label: "PROSJEKTER",
            url: "/prosjekter",
        },
        {
            label: "WIMA EIENDOM",
            url: "/eiendom",
        }
        ]} /></>

const Footer = () => <FooterSmall
    logo={img.logo}
    email={"kontakt@wimabygg.no"}
    paragraphs={[
        "Wima AS",
        "Niels Juels gate 10",
        "0272 Oslo",
        "Norway",
    ]}
/>

export default [
    {
        path: "/",
        element: <>
            <Header />
            <HeroSlider
                title="Din partner for byggeprosjekter"
                subtitle="Wima tilbyr et bredt spekter av håndverkertjenester, som gjør oss i stand til å håndtere alle deler av ditt byggeprosjekt"
            />
            <BoxCircle
                title="Våre tjenester"
                subtitle="Når det kommer til å transformere ditt hjem, er det detaljene som teller. Vi er dedikert til å levere håndverk av høyeste kvalitet som gir ditt hjem karakter og personlighet"
                items={[{
                    title: "NYBYGG OG TILBYGG",
                    image: img.house,
                    link: "/nybygg",
                }, {
                    title: "TOTALRENOVERING OG OPPUSSING",
                    image: img.hammer,
                    link: "/totalrenovering",
                }, {
                    title: "BAD OG KJØKKEN",
                    image: img.shover,
                    link: "/bad",
                }, {
                    title: "TAK, TERRASSE OG UTEOMRÅDE",
                    image: img.roof,
                    link: "/tak",
                }, {
                    title: "MURING OG BETONGARBEID",
                    image: img.bricks,
                    link: "/muring",
                }, {
                    title: "MØBELSNEKRING",
                    image: img.sofa,
                    link: "/mobelsnekring",
                }]}
            />
            <CtaText
                title="Klar for ditt nye byggeprosjekt?"
                subtitle="Vårt team av erfarne håndverkere er her for å bringe din visjon til liv.
                    Kontakt oss i dag for en personlig konsultasjon"
                buttonText="Kontakt oss"
            />
            <Footer />
        </>
    },
    {
        path: "/tjenester",
        element: <>
            <Header />
            <PictureText
                title="Våre tjenester"
                subtitle="Når det kommer til å transformere ditt hjem, er det detaljene som teller. Vi er dedikert til å levere håndverk av høyeste kvalitet som gir ditt hjem karakter og personlighet"
            />
            <GalleryButtons
                items={[{
                    image: img.modernHouse,
                    title: "Nybygg og tilbygg",
                    link: "/nybygg",
                }, {
                    image: img.plankDust,
                    title: "Totalrenovering og oppussing",
                    link: "/totalrenovering",
                }, {
                    image: img.modernBathroom,
                    title: "Bad og kjøkken",
                    link: "/bad",
                }, {
                    image: img.roofTiles,
                    title: "Tak, terrasse og uteområde",
                    link: "/tak",
                }, {
                    image: img.concreteWorker,
                    title: "Muring og betongarbeid",
                    link: "/muring",
                }, {
                    image: img.Mobelsnekker,
                    title: "Møbelsnekring",
                    link: "/mobelsnekring",
                }
            ]}
            />
            <CtaText
                title="Klar for ditt nye byggeprosjekt?"
                subtitle="Vårt team av erfarne håndverkere er her for å bringe din visjon til liv.
                    Kontakt oss i dag for en personlig konsultasjon"
                buttonText="Kontakt oss"
            />
            <Footer />
        </>
    },
    {
        path: "/om-oss",
        element: <>
            <Header />
            <CardImageRight 
                title="Om oss"
                image={img.elisenbergveien_laser_5}
                paragraphs={[
                    "Wima representerer en blanding av tradisjonell håndverkskunst og moderne byggeteknikker. Vi er et engasjert team som streber etter å levere eksepsjonell kvalitet i hvert prosjekt, enten det gjelder enkel oppussing eller komplekse byggearbeider",
                    "Vår tilnærming er å kombinere detaljert planlegging med materialer av høy kvalitet for å sikre at hvert prosjekt ikke bare møter, men overgår kundens forventninger. Vår styrke ligger i vår tilpasningsdyktighet og vårt fokus på kundens behov, noe som har ført til et sterkt rykte i bransjen"
                ]}
            />
            <CardImageLeft 
                title="Vår historie"
                image={img.kjokken}
                paragraphs={[
                    "Selv om Wima er et relativt ungt selskap, er vårt team sammensatt av individuelle fagfolk med lang og bred erfaring innen byggebransjen. Grunnlagt med en visjon om å bringe nytt liv til byggebransjen, kombinerer vi ungdommelig innovasjon med årtier av håndverkserfaring",
                    "Vår reise begynte med et ønske om å skape et byggefirma som ikke bare leverer overlegne resultater, men også bygger sterke relasjoner med våre kunder. Vår vekst har vært et resultat av vår urokkelige forpliktelse til kvalitet og kundetilfredshet, noe som står i hjertet av alt vi gjør"
                ]}
            />

            <Footer />
        </>
    },
    {
        path: "/prosjekter",
        element: <>
            <Header />
            <PictureText
                title="Våre prosjekter"
                subtitle="Ta en titt på våre referanseprosjekter for å få et inntrykk av arbeidet vi utfører. Disse prosjektene reflekterer vårt engasjement for kvalitet og vår evne til å møte ulike kundebehov. Vår portefølje inkluderer en rekke prosjekter, fra nybygg til renoveringer, og viser vår erfaring og tilnærming i praksis"
            />
            {/* <GalleryButtons_Texted /> */}
            <GalleryButtons
                items={[{
                    image: img.nedre_askerhagen6,
                    title: "Nedre Askerhagen 6",
                    link: "https://www.finn.no/eiendom/nybygg/prosjekt?finnkode=319214442&q=nedre+asker+hagen+6",
                }, {
                    image: img.Elisenbergveien_5,
                    title: "Elisenbergveien 5",
                }, {
                    image: img.Jacob_AAlls_gate,
                    title: "Jacobs aalls gate 1",
                }, {
                    image: img.Huldreveien_4,
                    title: "Huldreveien 4",
                }, {
                    image: img.midttunveien,
                    title: "Midttunveien 8",
                }, {
                    image: img.modernHouse, //??
                    title: "Folke Bernadottes vei 13",
                }, {
                    image: img.Ankerveien_28,
                    title: "Ankerveien 28",
                }, {
                    image: img.Ringshusveien,
                    title: "Ringshusveien 20",
                },
                {
                    image: img.Stølekilen, //??
                    title: "Stølekilen",
                },
            ]} />
            <CtaText
                title="Klar for ditt nye byggeprosjekt?"
                subtitle="Vårt team av erfarne håndverkere er her for å bringe din visjon til liv.
                    Kontakt oss i dag for en personlig konsultasjon"
                buttonText="Kontakt oss"
            />
            <Footer />
        </>
    },
    {
        path: "/eiendom",
        element: <>
            <Header />
            <CardImageRight
                title="Wima Eiendom"
                image={img.nedre_askerhagen6}
                paragraphs={[
                    "Wima Eiendom er et selskap som spesialiserer seg på eiendomsutvikling av privatboliger. Som et tilknyttet selskap av Wima, bærer det med seg en forpliktelse til kvalitet og innovasjon, noe som er kjernen i vårt arbeid. Vår tilnærming innebærer en nøye vurdering av eiendomspotensialer, hvor vi ser etter unike muligheter til å skape hjem som ikke bare tilfredsstiller, men overgår kundenes forventninger. Vi kombinerer vår ekspertise innen bygging og design for å transformere hver eiendom til et ideelt hjem.",
                    "I kjernen av Wima Eiendoms virksomhet ligger et sterkt fokus på bærekraft og samfunnsansvar. Vi arbeider for å utvikle boliger som ikke bare er estetisk tiltalende, men også miljøvennlige og energieffektive. Vår prosess omfatter alt fra grundig markedsanalyse til detaljert planlegging og gjennomføring, sikrer at vi skaper verdifulle og varige boliger. Med et engasjert team av fagfolk, står Wima Eiendom for eiendomsutvikling som gjenspeiler moderne boformer og fremtidens boligtrender."
                ]}
                style={{ paddingBottom: "100px" }}
            />
            <Footer />
        </>
    },
    {
        path: "/kontakt",
        element: <>
            <Header />
            <FormContactLarge />
            <Footer />
        </>
    },
    {
        path: "/nybygg",
        element: <>
            <Header />
            <CardImageRight
                title="Nybygg og tilbygg med presisjon og kreativitet"
                image={img.modernHouse}
                paragraphs={[
                    "Hos Wima er vi eksperter på å skape nye strukturer og tilbygg som ikke bare utvider ditt boareal, men også forbedrer din livskvalitet. Vårt dedikerte team arbeider tett med deg for å sikre at hver ny konstruksjon eller utvidelse reflekterer dine behov og preferanser.",
                    "Fra grunnleggende planlegging til ferdigstillelse, fokuserer vi på detaljene som gjør ditt nye rom både funksjonelt og estetisk tiltalende. Vi bruker de nyeste teknikkene og materialene for å garantere holdbarhet og stil som varer.",
                ]}
                style={{ paddingBottom: "100px" }}
            />
            <Footer />
        </>
    },
    {
        path: "/totalrenovering",
        element: <>
            <Header />
            <CardImageRight
                title="Gjenskap ditt hjem med våre totalrenoveringer"
                image={img.roomRenovation}
                paragraphs={[
                    "Wimas team av håndverkere er lidenskapelig opptatt av å forvandle eksisterende rom til moderne og innbydende områder. Vår tilnærming til totalrenovering og oppussing handler om å bringe nytt liv til hvert rom, samtidig som vi respekterer og bevare hjemmets unike karakter.",
                    "Vi kombinerer tradisjonelt håndverk med moderne design for å skape tidløse og funksjonelle områder. Enten det er en komplett overhaling eller subtile forbedringer, vårt mål er å overskride dine forventninger med sluttresultatet.",
                ]}
                style={{ paddingBottom: "100px" }}
            />
            <Footer />
        </>
    },
    {
        path: "/bad",
        element: <>
            <Header />
            <CardImageRight
                title="Skap ditt drømmekjøkken og baderom med Wima"
                image={img.modernBathroom}
                paragraphs={[
                    "Kjøkkenet og badet er blant de viktigste rommene i et hjem, og Wima har spesialisert seg på å designe og bygge disse rommene for å gjenspeile din personlige stil og funksjonelle behov. Vi forstår viktigheten av detaljer, fra benkeplater til flislegging.",
                    "Med et øye for estetikk og funksjonalitet, tilbyr vårt team innovative løsninger som maksimerer rommet og forbedrer brukskomforten. Vi sørger for at hvert prosjekt er en unik refleksjon av din personlighet og livsstil.",
                ]}
                style={{ paddingBottom: "100px" }}
            />
            <Footer />
        </>
    },
    {
        path: "/tak",
        element: <>
            <Header />
            <CardImageRight
                title="Forvandle utendørsområdene med Wima"
                image={img.roofTiles}
                paragraphs={[
                    "Wima er her for å hjelpe deg med å forvandle ditt utendørsområde til et fantastisk uterom. Vårt team av eksperter har erfaring med å designe og bygge alt fra robuste tak til elegante terrasser og andre uteområder som komplementerer ditt hjem og landskap.",
                    "Vi forstår hvordan man optimaliserer utendørsområder for å skape vakre, funksjonelle og innbydende steder for avslapning og underholdning. Våre løsninger er skreddersydd for å tilpasse seg både dine estetiske preferanser og de praktiske behovene til din livsstil.",
                ]}
                style={{ paddingBottom: "100px" }}
            />
            <Footer />
        </>
    },
    {
        path: "/muring",
        element: <>
            <Header />
            <CardImageRight
                title="Solid håndverk i muring og betongarbeid"
                image={img.concreteWorker}
                paragraphs={[
                    "Hos Wima tar vi muring og betongarbeid til neste nivå med vår ekspertise og oppmerksomhet på detaljer. Vårt team av fagfolk leverer høykvalitets arbeid som garanterer styrke og holdbarhet for alle typer strukturer.",
                    "Vi tilbyr en rekke tjenester, fra estetisk til funksjonell muring, og sørger for at hvert prosjekt blir utført med største presisjon. Enten det er fundamenter, vegger eller dekorative elementer, vårt arbeid står for kvalitet og pålitelighet.",
                ]}
                style={{ paddingBottom: "100px" }}
            />
            <Footer />
        </>
    },
    {
        path: "/mobelsnekring",
        element: <>
            <Header />
            <CardImageRight
                title="Skreddersydde møbler og snekkerarbeid"
                image={img.Mobelsnekker}
                paragraphs={[
                    "Wima er stolte av å tilby skreddersydde møbler og snekkerarbeid av høyeste kvalitet. Vårt team av erfarne håndverkere har en lidenskap for å skape unike og funksjonelle møbler som er tilpasset dine behov og preferanser.",
                    "Vi tilbyr en rekke tjenester, fra skreddersydde møbler til spesialtilpassede hyller og andre snekkerarbeider. Vårt fokus på detaljer og kvalitet sikrer at hvert prosjekt er en refleksjon av din personlige stil og smak.",
                ]}
                style={{ paddingBottom: "100px" }}
            />
            <Footer />
        </>
    },

]
